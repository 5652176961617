<template>
    <default-layout>

        <mdb-container class="pt-4">
            <form @submit.prevent="(evt) => generarFactura(evt)" no-validate>
                <mdb-row>
                    <h4>Datos de reserva</h4>
                    <mdb-col sm="12" md="4">
                        <mdb-input label="Localizador de factura" placeholder="Introduce el localizador de factura" required v-model="dataForm.localizadorFactura"/>
                    </mdb-col>
                </mdb-row>
    
                <mdb-row>
                    <h4>Datos fiscales</h4>
                </mdb-row>
                <mdb-row>
                    <mdb-col sm="12" md="4">
                        <mdb-input label="Nombre Fiscal" required v-model="dataForm.nombreFiscal"/>
                    </mdb-col>
                    <mdb-col sm="12" md="4">
                        <mdb-input label="Domicilio" v-model="dataForm.domicilio" required/>
                    </mdb-col>
                    <mdb-col sm="12" md="4">
                        <mdb-input label="Población" v-model="dataForm.poblacion" required/>
                    </mdb-col>
                    <mdb-col sm="12" md="4">
                        <mdb-input label="Código Postal" v-model="dataForm.codigoPostal" required/>
                    </mdb-col>
                    <mdb-col sm="12" md="4">
                        <mdb-input label="Provincia" v-model="dataForm.provincia" required/>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col sm="12" md="4">
                        <mdb-input label="CIF" v-model="dataForm.cif" required/>
                    </mdb-col>
                    <mdb-col sm="12" md="4">
                        <mdb-input label="Teléfono" v-model="dataForm.telefono" required/>
                    </mdb-col>
                    <mdb-col sm="12" md="4">
                        <mdb-input label="Email" v-model="dataForm.email" required/>
                    </mdb-col>
                    <mdb-col sm="12" md="4">
                        <mdb-input label="Observaciones" v-model="dataForm.observaciones"/>
                    </mdb-col>
                </mdb-row>
    
                <mdb-row>
                    <mdb-col class="text-left" col="12">
                        <mdb-input type="checkbox" label="Una vez emitida su factura, no podrá ser modificada, una vez generada no hay cambios posibles" id="accept-check" v-model="accept" required/>
                    </mdb-col>
                    <mdb-col class="text-left" col="12">
                        <mdb-input type="checkbox" label="Acepto que los datos de facturación son correctos" id="doubleCheck-check" v-model="doubleCheck" required/>
                    </mdb-col>
                </mdb-row>
    
                <mdb-btn outline="red" type="submit" :disabled="disableForm">GENERAR FACTURA</mdb-btn>
            </form>
    
            <success-alert :show="facturaGeneradaAlert" :mensaje="'La factura se generó con éxito, recibirá una copia a su correo'" @close="() => facturaGeneradaAlert = false"/>
            <error-alert :show="errorFacturaAlert" :mensaje="'Hubo un problema al generar su factura, revise el número de pedido'" @close="() => errorFacturaAlert = false"/>
            <error-alert :show="errorDuplicadoAlert" :mensaje="'Ya existe una factura asociada a este número de pedido'" @close="() => errorDuplicadoAlert = false"/>
            <error-alert :show="errorIVA" :mensaje="'Lo sentimos, el monumento con el que quiere facturar no tiene activo este servicio. Por favor, contacte directamente con el monumento'" @close="() => errorIVA = false"/>
    
        </mdb-container>
    </default-layout>
</template>

<script>
import FacturacionService from '../../services/FacturacionService';
import ErrorAlert from '../../components/ErrorAlert.vue';
import SuccessAlert from '../../components/SuccessAlert.vue';
import * as printJS from "print-js";

export default {
  components: { SuccessAlert, ErrorAlert },
    data() {
        return{
            dataForm: {
                numeroDePedido: null,
                nombreFiscal: null,
                domicilio: null,
                poblacion: null,
                codigoPostal: null,
                provincia: null,
                cif: null,
                telefono: null,
                email: null,
                observaciones: null,
                ticketing: true
            },
            accept: false,
            doubleCheck: false,
            facturaGeneradaAlert: false,
            errorFacturaAlert: false,
            errorDuplicadoAlert: false,
            errorIVA: false,
            disableForm: false
        }
    },
    methods:{
        async generarFactura(evt){
            evt.preventDefault();

            this.disableForm = true;

            try{
                const pedido = await FacturacionService.checkPedido( this.dataForm );
    
                if( pedido.error === 'error' ){
                    this.errorFacturaAlert = true;
                    return;
                }
                else if( pedido.error === 'duplicated' ){
                    this.errorDuplicadoAlert = true;
                    return;
                }
    
                const invoice = await FacturacionService.createInvoice( this.dataForm );
                
                // console.log( invoice );
                const pdfBlob = new Blob([invoice], { type: "application/pdf" })
    
                const url = URL.createObjectURL(pdfBlob)
                printJS({
                    printable: url,
                    type: 'pdf'
                })
                URL.revokeObjectURL(pdfBlob)
                this.creatingPDF = false;
                this.disableForm = false;
            }
            catch( err ){
                this.disableForm = false;
                this.creatingPDF = false;
            }
        }
    },
    async created(){
        const {numeroDePedido} = this.$route.query;

        this.dataForm.numeroDePedido = numeroDePedido
        if( numeroDePedido ){
            try{
                const result = await FacturacionService.checkPedido( {numeroDePedido: numeroDePedido} );
    
                switch ( result.error ){
                    case "Desactivado":
                        this.disableForm = true;
                        this.errorIVA = true;
                        break;
                    case "duplicated":
                        this.errorDuplicadoAlert = true;
                        break;
                    case "error":
                        this.errorFacturaAlert = true;
                        break;
                    default:
                        break;
                }
            }
            catch( err ){
                console.log( err );
            }
        }
    }
}
</script>